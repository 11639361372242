import { render, staticRenderFns } from "./RegistrationStatusAndSwitcher.vue?vue&type=template&id=60ae541a&scoped=true&lang=pug&"
import script from "./RegistrationStatusAndSwitcher.vue?vue&type=script&lang=js&"
export * from "./RegistrationStatusAndSwitcher.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationStatusAndSwitcher.vue?vue&type=style&index=0&id=60ae541a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60ae541a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
