<template lang="pug">
v-bottom-sheet(v-model="miniAppBottomSheet" fullscreen)
  v-sheet
    iframe.re-fullscreen-iframe(ref="miniAppIframe" :src="miniAppIframeSrc" allow="microphone")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import $http from '../../utils/http'
import { getLocale } from '../../i18n'

export default {
  name: 'MiniApp',

  data() {
    return {
      miniAppBottomSheet: false,
      miniAppIframeSrc: null,
      miniAppIAB: null,
      miniAppTimerId: null,
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      visible: state => state.miniapp.visible,
      app: state => state.miniapp.app,
    }),
  },

  watch: {
    visible(value) {
      if (value) {
        this.open()
      }
    },
  },

  methods: {
    ...mapActions({
      hide: 'miniapp/hide',
      init: 'cashRegisters/init',
      setGlobalLoading: 'tools/setGlobalLoading',
      analyticsLogEvent: 'analytics/logEvent',
      copyToClipboard: 'tools/copyToClipboard',
      showSnackbar: 'tools/showSnackbar',
    }),

    open() {
      const url = new URL(this.app.url)
      url.searchParams.append('themeDark', this.$vuetify.theme.dark)
      url.searchParams.append('phone', this.user.phone)
      url.searchParams.append('lang', getLocale())
      url.searchParams.append('isApp', this.$isCordova())
      url.searchParams.append('iinBin', this.organization.businessId)
      url.searchParams.append('cashRegisterId', this.cashRegister.id)

      console.debug('MiniApp params: ', this.app.params)

      if (this.app.params && this.app.params.length > 0) {
        this.app.params.forEach(item => {
          const [key, value] = Object.entries(item)[0]
          url.searchParams.append(key, value)
        })
      }

      if (this.$isCordova()) {
        this.miniAppIAB = undefined
        this.miniAppIAB = window.cordova.InAppBrowser.open(url.toString(), '_blank', 'location=no,toolbar=no,hidenavigationbuttons=yes,hideurlbar=yes,zoom=no,enableViewportScale=no,hardwareback=yes')
        this.miniAppIAB.addEventListener('message', this.onMessage)
        this.miniAppIAB.addEventListener('exit', this.onExit)
      } else {
        window.addEventListener('message', this.onMessage, false)
        this.miniAppIframeSrc = url.toString()
        this.miniAppBottomSheet = true
      }

      this.miniAppTimerId = setTimeout(() => {
        this.close()
        this.showSnackbar({ message: this.$t('произошла_ошибка') })
      }, 20000)
    },

    onMessage(event) {
      if (event.data && (this.$isCordova() || this.checkOrigin(event.origin, this.app.url))) {
        console.debug(event)

        try {
          const { id, method, param } = this.$isCordova() ? event.data : JSON.parse(event.data)

          // loaded
          if (method === 'loaded') {
            if (this.miniAppTimerId) clearTimeout(this.miniAppTimerId)
            this.postMessage(id)
            if (!this.$isCordova()) {
              this.$refs.miniAppIframe.focus()
            }
          }

          // urlParams
          if (method === 'urlParams') {
            const urlParams = {
              phone: this.user.phone,
              lang: getLocale(),
              isApp: this.$isCordova(),
            }
            urlParams.iinBin = this.organization.businessId
            urlParams.cashRegisterId = this.cashRegister.id
            this.postMessage(id, urlParams)
          }

          // close
          if (method === 'close') {
            if (this.miniAppTimerId) clearTimeout(this.miniAppTimerId)

            this.close()
            this.postMessage(id)

            // Перезагружаем список касс
            if (param && param.refresh === true) {
              this.setGlobalLoading(true)
              this.init().then(() => {
                this.setGlobalLoading(false)
              })
            }

            // Перезагружаем список касс и выбираем кассу
            if (param && param.select) {
              this.setGlobalLoading(true)
              this.init({ params: { cashRegisterId: param.select } }).then(() => {
                this.setGlobalLoading(false)
              })
            }
          }

          // customToken
          if (method === 'customToken') {
            $http.post(`/api/auth/app/login?app=${this.app.name}`).then((response) => {
              this.postMessage(id, response.data)
            }).catch((error) => {
              console.error(error)
            })
          }

          // barcode
          if (method === 'barcode') {
            const aspectRatio = parseInt(localStorage.getItem('rekassa.kz-ui-camera-aspectRatio') || 1, 10)
            window.cordova.plugins.rekassaBarcode.scan((result) => {
              this.postMessage(id, `${result}`)
            },
            () => {
            }, { aspectRatio })
          }

          // analytics
          if (method === 'analytics') {
            // Проверяем, является ли param строкой или объектом
            if (typeof param === 'string') {
              // Старый формат, когда param — строка
              this.analyticsLogEvent({ eventName: param })
            } else if (typeof param === 'object' && param !== null) {
              // Новый формат, когда param — объект с key-value
              this.analyticsLogEvent({ eventName: param.eventName, eventProperties: param.eventProperties })
            }
            this.postMessage(id)
          }

          // href
          if (method === 'href') {
            if (this.$isCordova()) {
              this.miniAppIAB = this.$openLink(param, '_system')
              this.miniAppIAB.addEventListener('message', this.onMessage)
              this.miniAppIAB.addEventListener('exit', this.onExit)
            } else {
              this.$openLink(param, '_system')
            }

            this.postMessage(id)
          }

          // share
          if (method === 'share') {
            if (this.$isCordova()) {
              window.plugins.socialsharing.shareW3C({
                title: this.$t('отправить_ссылку'),
                text: param,
              })
            } else {
              this.copyToClipboard(param)
            }
            this.postMessage(id)
          }

          // clipboard
          if (method === 'clipboard') {
            this.copyToClipboard(param)
            this.postMessage(id)
          }

          // appInstalled
          if (method === 'appInstalled' && this.$isCordova()) {
            if (param === 'egov') {
              window.cordova.plugins.rekassaAppavailability.hasEgovMobile((result) => {
                this.postMessage(id, result)
              }, () => {})
            } else if (param === 'egovBusiness') {
              window.cordova.plugins.rekassaAppavailability.hasEgovBusiness((result) => {
                this.postMessage(id, result)
              }, () => {})
            }
          }
        } catch (error) {
          // console.error(error)
        }
      }
    },

    onExit() {
      this.miniAppIAB.removeEventListener('message', this.onMessage)
      this.miniAppIAB = undefined
      this.hide()
    },

    close() {
      if (this.$isCordova()) {
        this.miniAppIAB.close()
      } else {
        this.miniAppBottomSheet = false
        this.miniAppIframeSrc = null
        window.removeEventListener('message', this.onMessage, false)
      }
      this.hide()
    },

    checkOrigin(origin, url) {
      try {
        const parsedUrl = new URL(url)
        return parsedUrl.origin === origin
      } catch (e) {
        console.error('Invalid URL:', e)
        return false
      }
    },

    postMessage(id, result) {
      if (this.$isCordova()) {
        this.miniAppIAB.executeScript({ code: `window.onPostMessage('${id}', '${JSON.stringify(result)}');` })
      } else {
        this.$refs.miniAppIframe.contentWindow.postMessage(JSON.stringify({ id, result }), this.app.url)
      }
    },

  },

}
</script>

<style lang="stylus">
</style>
