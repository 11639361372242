<template lang="pug">
v-row
  v-col.pb-5(cols="12" md="6")
    v-sheet.pa-5.pa-md-8.align-self-stretch.rounded-xl.re-cursor-pointer(@click="navigateToJournal(false)" style="height: 100%;")
      template(v-if="cashRegister.shiftOpen")
        .d-flex.justify-space-between.mb-2.mb-md-7
          p.text-body-1.text-md-h4 {{ `${$t("текущая_смена")} - №${shiftNumber}` }}
          p.text-body-1.text-md-h4.tertiary--text {{ shiftStartDateTime | moment("HH:mm - DD.MM.YYYY") }}
        .d-flex.justify-space-between.align-center
          p.text-h2.mb-0.text-md-h2.text-lg-h1.re-number-font {{ shiftRevenue | numeral('0,0.[00]') }} ₸
          v-chip.py-md-2.px-md-4.d-table.py-1(x-small color="reBackground lighten-2")
            p.text-subtitle-2.mb-0.text-md-h4 {{ `${shiftTicketNumber} ${numWord(shiftTicketNumber)}` }}
        template(v-if="cashRegister.shiftExpired")
          v-btn.mt-4.mb-2.mt-md-8.d-flex.mx-auto.text-subtitle-2.text-md-subtitle-1.rounded-lg(color="reBackground lighten-2" width="100%" @click.stop="navigateToJournal(true)")
            v-icon.mr-3(left size="20" color="red") mdi-alert-circle
            | {{ $t("необходимо_закрыть_смену") }}
      template(v-else)
        p.text-subtitle-2.text-center.text-md-h3 {{ $t("нет_открытой_смены") }}
        v-btn.mt-4.mb-2.mt-md-8.d-flex.mx-auto.text-subtitle-2.text-md-subtitle-1.rounded-lg(width="100%" color="orange" @click.stop="$router.push('/kkm')") {{ $t("открыть_новую_смену") }}

  v-col.d-flex.py-0.py-md-3.px-4(cols="12" md="6")
    v-row

      //- First Button ----------------------------------------------------------------------------
      v-col.d-flex.pt-md-0.pa-2(cols="4" @click="showMiniAppUPlus(); analyticsLogEvent({ eventName: 're_inventory_clicked_menu', eventProperties: { section_source: 'from_re_main_page' } })")
        v-sheet.pa-4.flex-grow-1.align-self-stretch.rounded-xl.re-cursor-pointer.justify-center.align-center
          .d-flex.flex-column.align-center
            v-avatar.mt-md-3(color="reBackground" :size="avatarSize")
              v-icon(:size="avatarIconSize") mdi-package-variant
            p.text-center.text-subtitle-2.text-md-h4.mt-2.mt-md-3 {{ $t("товары_и_услуги") }}
        div(style="position: relative;")
          .text-subtitle-2.new-badge new

      //- Second Button ----------------------------------------------------------------------------
      v-col.d-flex.pt-md-0.pa-2(cols="4" @click="showMiniAppNA(); analyticsLogEvent({ eventName: 're_salyq_clicked_menu', eventProperties: { section_source: 'from_re_main_page' } })")
        v-sheet.pa-4.flex-grow-1.align-self-stretch.rounded-xl.re-cursor-pointer.justify-center.align-center
          .d-flex.flex-column.align-center
            v-avatar.mt-md-3(color="reBackground" :size="avatarSize")
              v-icon(:size="avatarIconSize") mdi-file-percent-outline
            p.text-center.text-subtitle-2.text-md-h4.mt-2.mt-md-3 {{ $t("налоги") }}
        div(style="position: relative;")
          .text-subtitle-2.new-badge new

      //- Third Button ----------------------------------------------------------------------------
      template(v-if="!isTestMode")
        v-col.d-flex.pt-md-0.pa-2(cols="4" @click="$openLink(`https://link.rekassa.kz/help-rekassa-${locale}`, '_system'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'manuals' } })")
          v-sheet.py-4.pa-md-4.flex-grow-1.align-self-stretch.rounded-xl.re-cursor-pointer.justify-center.align-center
            .d-flex.flex-column.align-center
              v-avatar.mt-md-3(color="reBackground" :size="avatarSize")
                v-icon(:size="avatarIconSize") mdi-microsoft-onenote
              p.text-center.text-subtitle-2.text-md-h4.mt-2.mt-md-3 {{ $t("справочник") }}

      template(v-else)
        v-col.d-flex.pt-md-0.pa-2(cols="4" @click="showMiniAppLoyalty(); analyticsLogEvent({ eventName: 're_loyalty_clicked_menu', eventProperties: { section_source: 'from_re_main_page' } })")
          v-sheet.py-4.pa-md-4.flex-grow-1.align-self-stretch.rounded-xl.re-cursor-pointer.justify-center.align-center
            .d-flex.flex-column.align-center
              v-avatar.mt-md-3(color="reBackground" :size="avatarSize")
                v-icon(:size="avatarIconSize") mdi-star
              p.text-center.text-subtitle-2.text-md-h4.mt-2.mt-md-3 Rewards
          div(style="position: relative;")
            .text-subtitle-2.new-badge new

  v-col(v-if="isFNO910Available")
    v-row
      v-col(@click="showMiniAppNA(); analyticsLogEvent({ eventName: 're_salyq_clicked_menu', eventProperties: { section_source: 'from_re_main_910' } })")
        v-sheet.flex-grow-1.rounded-xl.px-5.py-4.re-wave.re-cursor-pointer(color="orange")
          .d-flex.justify-space-between
            .d-flex.flex-column
              .text-h5.font-weight-bold {{ $t("отчет_по_форме_910") }}
              .text-subtitle-2 {{ $t("до_15_февраля") }}
            .d-flex.align-center
              v-chip.rounded-xl.text-body-1.font-weight-bold.text-uppercase {{ $t("сдать_отчет") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../../i18n/index'
import billsAndCoinsMixin from '../../../mixins/billsAndCoinsMixin'

export default {
  mixins: [billsAndCoinsMixin],

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      cashRegisterItemListNewCount: state => state.cashRegisters.cashRegisterItemList.filter(item => item.cashRegister.status === 'NEW').length,
    }),

    locale() {
      return getLocale()
    },

    shift() {
      return this.cashRegister ? this.cashRegister.shift : null
    },

    shiftNumber() {
      if (this.shift && this.shift.open && this.shift.shiftNumber) {
        return this.shift.shiftNumber
      }
      return null
    },

    shiftRevenue() {
      if (this.shift && this.shift.open && this.shift.data.revenue) {
        return this.getNumberFromBillsAndCoins(this.shift.data.revenue.sum) * (this.shift.data.revenue.isNegative ? -1 : 1)
      }
      return null
    },

    shiftTicketNumber() {
      if (this.cashRegister && this.shift && this.shift.open && this.shift.shiftTicketNumber > 0) {
        return this.shift.shiftTicketNumber
      }
      return 0
    },

    shiftStartDateTime() {
      if (this.shift && this.shift.openTime) {
        return this.shift.openTime
      }
      return null
    },

    isLarge() {
      return this.$vuetify.breakpoint.mdAndUp
    },

    avatarSize() {
      return this.isLarge ? '80px' : '65px'
    },

    avatarIconSize() {
      return this.isLarge ? '44px' : '36px'
    },

    isFNO910Available() {
      const now = new Date()
      return !this.preferences?.resalyq?.fno910?.isDistributed && (now.getMonth() === 1 && now.getDate() <= 15) && this.cashRegister.status === 'REGISTERED'
    },

    isTestMode() {
      return process.env.VUE_APP_TEST === 'true'
    },
  },

  watch: {
    $route() {
      this.handleRouteChange()
    },
  },

  mounted() {
    this.handleRouteChange()
  },

  methods: {
    ...mapActions({
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      showCashRegisterSwitch: 'tools/showCashRegisterSwitch',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
      showMiniApp: 'miniapp/show',
    }),

    showMiniAppUPlus(query) {
      if (!query) query = {}
      this.showMiniApp({
        url: process.env.VUE_APP_MINIAPP_UPLUS_URL,
        name: process.env.VUE_APP_MINIAPP_UPLUS_NAME,
        params: [
          { viewType: 'home' },
          ...Object.entries(query).map(([key, value]) => ({
            [key]: value,
          })),
        ],
      })
    },

    showMiniAppNA(query) {
      if (!query) query = {}
      this.showMiniApp({
        url: process.env.VUE_APP_MINIAPP_NA_URL,
        name: process.env.VUE_APP_MINIAPP_NA_NAME,
        params: Object.entries(query).map(([key, value]) => ({
          [key]: value,
        })),
      })
    },

    showMiniAppLoyalty(query) {
      if (!query) query = {}
      this.showMiniApp({
        url: process.env.VUE_APP_MINIAPP_LOYALTY_URL,
        name: process.env.VUE_APP_MINIAPP_LOYALTY_NAME,
        params: Object.entries(query).map(([key, value]) => ({
          [key]: value,
        })),
      })
    },

    showMiniAppOFD(query) {
      if (this.cashRegisterItemListNewCount >= process.env.VUE_APP_CASHREGISTERS_NEWCOUNTLIMIT) {
        this.showSnackbar({ message: this.$t('превышен_лимит_на_кол_во_новых_касс_допустимое_кол-во', { limit: process.env.VUE_APP_CASHREGISTERS_NEWCOUNTLIMIT }) })
        this.$router.push('/')
        return
      }

      if (!query) query = {}
      this.showMiniApp({
        url: process.env.VUE_APP_MINIAPP_OFD_URL,
        name: process.env.VUE_APP_MINIAPP_OFD_NAME,
        params: Object.entries(query).map(([key, value]) => ({
          [key]: value,
        })),
      })
    },

    // Ловим deeplinks
    handleRouteChange() {
      const { path, fullPath, query } = this.$route

      // Handle '/list' path
      if (path === '/list') {
        this.showCashRegisterSwitch(true)
        this.$router.push('/')
        return
      }

      // Handle '/miniapp' path with query name
      if (fullPath.startsWith('/miniapp') && query.name) {
        const { name } = query

        if (name === 'uplus') {
          this.showMiniAppUPlus(query)
        } else if (name === 'na') {
          this.showMiniAppNA(query)
        } else if (name === 'ofd') {
          this.showMiniAppOFD(query)
        } else if (name === 'loyalty') {
          this.showMiniAppLoyalty(query)
        }

        // Clear path
        this.$router.push('/')
      }
    },

    navigateToJournal(zxreport) {
      if (this.shiftNumber) {
        this.$router.push(`/shifts/${this.cashRegister.id}/${this.shiftNumber}${zxreport ? '/zxreport' : ''}`)
      }
    },

    numWord(value) {
      const words = this.locale === 'ru' ? ['чек', 'чека', 'чеков'] : ['чек', 'чек', 'чек']
      value = Math.abs(value) % 100
      const num = value % 10
      if (value > 10 && value < 20) return words[2]
      if (num > 1 && num < 5) return words[1]
      if (num === 1) return words[0]
      return words[2]
    },
  },
}
</script>
<style scoped lang="stylus">
.mdi-alert-circle::before
  background-color white
  border-radius 15px

.new-badge
  position absolute
  top 16px
  right -3px
  background-color rgb(244, 67, 54)
  padding 1px 7px
  border-top-left-radius 7px
  border-bottom-left-radius 7px
  border-top-right-radius 3px
  border-bottom-right-radius 3px
  color white

.re-wave
  background-image url('/static/wave.png')
  background-size cover
</style>
