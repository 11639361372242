import { setUserId, setUserProperties, logEvent } from 'firebase/analytics'

const analytics = {
  namespaced: true,

  state: {
    firebaseAnalytics: null,
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    setFirebaseAnalytics({ commit }, payload) {
      commit('set', { type: 'firebaseAnalytics', value: payload })
    },

    setUserId({ state }, payload) {
      if (window.cordova) {
        window.FirebasePlugin.setUserId(payload)
        window.plugins.appsFlyer.setAppUserId(payload)
      } else {
        setUserId(state.firebaseAnalytics, payload)
      }
    },

    setUserProperty({ state }, payload) {
      if (window.cordova) {
        window.FirebasePlugin.setUserProperty(payload.key, payload.value)
      } else {
        const jsonVariable = {}
        jsonVariable[payload.key] = payload.value
        setUserProperties(state.firebaseAnalytics, jsonVariable)
      }
    },

    logEvent({ state, rootState }, payload) {
      const eventProperties = payload.eventProperties || {}

      // Prevent sending phone number and user_id to analytics from miniapps
      eventProperties.phone_number = undefined
      eventProperties.user_id = undefined

      // Pass user uid, cash register id and organization id to analytics by default
      if (rootState.auth?.user?.uid) {
        eventProperties.user_id = rootState.auth.user.uid
      }
      if (rootState.cashRegisters?.cashRegister?.cashRegister?.id) {
        eventProperties.cash_register_id = rootState.cashRegisters.cashRegister.cashRegister.id
      }
      if (rootState.cashRegisters?.cashRegister?.organization?.id) {
        eventProperties.organization_id = rootState.cashRegisters.cashRegister.organization.id
      }

      if (process.env.VUE_APP_TEST === 'true') {
        console.debug(`logEvent: ${payload.eventName} ${eventProperties ? `eventProperties: ${JSON.stringify(eventProperties)}` : ''}`)
      }

      if (window.cordova) {
        window.FirebasePlugin.logEvent(payload.eventName, eventProperties)
        window.plugins.appsFlyer.logEvent(payload.eventName, eventProperties, null, (error) => {
          try {
            window.FirebasePlugin.logEvent('re_apps_flyer_logevent_error', { eventError: error, eventName: payload.eventName, eventProperties: payload.eventProperties })
          } catch (err) {
            // console.debug(err)
          }
        })
      } else {
        logEvent(state.firebaseAnalytics, payload.eventName, eventProperties)
        window.fbq('trackCustom', payload.eventName, eventProperties)
      }
    },

    setScreenName({ state }, payload) {
      if (window.cordova) {
        window.FirebasePlugin.setScreenName(payload)
        window.plugins.appsFlyer.logEvent('screen_view', { name: payload })
      } else if (state.firebaseAnalytics) {
        logEvent(state.firebaseAnalytics, 'screen_view', { firebase_screen: payload })
      }
    },

  },
}

export default analytics
